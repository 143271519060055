import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import * as Yup from "yup";
import {
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  RowDetailState,
  SortingState,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  Toolbar,
  TableFilterRow,
  TableHeaderRow,
  TableRowDetail,
  PagingPanel,
  TableColumnVisibility,
  ColumnChooser,
} from "@devexpress/dx-react-grid-bootstrap4";
import { Button, Modal, Form } from "react-bootstrap";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import config, { roles } from "../../../../config/config";
import { useFormik } from "formik";

const TableComponent = ({ ...restProps }) => (
  <Table.Table
    {...restProps}
    className="table table-hover table-head-custom table-vertical-center overflow-hidden"
  />
);

const filterRowMessages = {
  filterPlaceholder: "Filtra...",
};

export const TabellaDipendenti = (props) => {
  const { user } = useSelector((state) => state.auth);

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  const RowDetail = ({ row }) => {
    const [countryUser, setCountryUser] = useState({ label: "", value: "" });
    const [regionUser, setRegionUser] = useState({ label: "", value: "" });
    const [provinceUser, setProvinceUser] = useState({ label: "", value: "" });
    const [cityUser, setCityUser] = useState({ label: "", value: "" });

    //STATO PER GESTIRE LA SELEZIONE DI NAZIONE, REGIONE, CITTÀ
    const [province, setProvince] = useState([]);
    const [country, setCountry] = useState([]);
    const [regione, setRegione] = useState([]);
    const [city, setCity] = useState([]);

    const [selectedCountry, setSelectedCountry] = useState(
      row.country !== "" ? row.country : ""
    );
    const [selectedRegion, setSelectedRegion] = useState(
      row.region !== "" ? row.region : ""
    );
    const [selectedProvince, setSelectedProvince] = useState(
      row.province !== "" ? row.province : ""
    );
    const [selectedCity, setSelectedCity] = useState(
      row.city !== "" ? row.city : ""
    );

    //STATO PER GESTIRE I MESSAGGI DI ERRORE
    const [message] = useState(false);
    const [emailMessage, setEmailMessage] = useState(false);
    const [emailAziendaleMessage, setEmailAziendaleMessage] = useState(false);
    const [phoneMessage, setPhoneMessage] = useState(false);

    //VALIDAZIONE FORM

    const getInputClassesForHandle = (fieldname) => {
      if (formik.touched[fieldname] && formik.errors[fieldname]) {
        return "is-invalid";
      }

      if (formik.touched[fieldname] && !formik.errors[fieldname]) {
        return "is-valid";
      }
      return "";
    };

    const validationSchema = Yup.object({
      nome: Yup.string()
        .required("Inserisci un nome valido")
        .matches(
          /^[A-Za-zèàòì\s]+$/,
          "Il nome non può contenere numeri o simboli"
        ),
      cognome: Yup.string()
        .required("Inserisci un cognome valido")
        .matches(
          /^[A-Za-zèàòì\s]+$/,
          "Il cognome non può contenere numeri o simboli"
        ),
      email: Yup.string()
        .email("Inserire una mail valida")
        .required("Inserire una mail valida"),
      emailAziendale: Yup.string()
        .email("Inserire una mail valida")
        .notRequired("Inserire una mail valida"),
      phone: Yup.string()
        .matches(/^\d+$/, "Il numero di telefono non può contenere lettere")
        .notRequired("Inserire un numero di telefono valido"),
      zip: Yup.string()
        .matches(/^\d+$/, "Il CAP non può contenere lettere")
        .notRequired("Inserire un CAP valido"),
      vatNumber: Yup.string()
        .matches(/^\d+$/, "La partita IVA non può contenere lettere")
        .min(11, "La partita IVA deve essere composta da 11 numeri")
        .max(11, "La partita IVA deve essere composta da 11 numeri")
        .notRequired(),
      iban: Yup.string()
        .min(27, "L'iban deve essere lungo almeno 27 caratteri")
        .max(32, "L'iban può avere massimo 32 caratteri")
        .notRequired(),
    });

    //STATO PER GESTIRE IL FORM
    var customValues;
    if (props.customfield !== []) {
      var newValues = {};
      props.customfield.map((e) => {
        var k = e.slug;
        var result = row.customfield[e.slug];
        newValues[k] = result;
      });
      customValues = newValues;
    } else {
      customValues = {};
    }

    const [editForm, setEditForm] = useState({
      idDipendente: row.idUser,
      nome: row.nome,
      cognome: row.cognome,
      birthday: row.birth !== undefined ? row.birth : "1970-01-01",
      password: "",
      phone: row.phone !== undefined ? row.phone : "",
      email: row.email !== undefined ? row.email : "",
      emailAziendale:
        row.emailAziendale !== undefined ? row.emailAziendale : "",
      zip: row.zip,
      address: row.address,
      vatNumber: row.vatNumber,
      iban: row.iban,
      idRole: row.idRole,
      idDepartment: row.idDepartment,
    });

    const initialValues = { ...editForm, ...customValues };

    const GetCountry = async () => {
      const requestOptions = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
      };

      var rawData = await fetch(
        config.apiUrl + "geolocal/GetCountries.php",
        requestOptions
      );
      var data = await rawData.json();
      setCountry(data);
      setCountryUser(data.find((o) => o.label === row.country));
    };

    const GetProvince = async () => {
      const requestOptions = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
        body: JSON.stringify({
          idRegion: regionUser.value,
        }),
      };

      var rawData = await fetch(
        config.apiUrl + "geolocal/GetProvinces.php",
        requestOptions
      );
      var data = await rawData.json();
      setProvince(data);
      setProvinceUser(data.find((o) => o.label === row.province));
    };

    const GetCity = async () => {
      const requestOptions = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
        body: JSON.stringify({
          idProvince: provinceUser.value,
        }),
      };
      var rawData = await fetch(
        config.apiUrl + "geolocal/GetCities.php",
        requestOptions
      );
      var data = await rawData.json();
      setCity(data);
      setCityUser(data.find((o) => o.label === row.city));
    };

    const GetRegione = async () => {
      const requestOptions = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
        body: JSON.stringify({}),
      };
      var rawData = await fetch(
        config.apiUrl + "geolocal/GetRegions.php",
        requestOptions
      );
      var data = await rawData.json();
      setRegione(data);
      console.log(row)
      setRegionUser(data.find((o) => o.label === row.region));
    };

    const DeleteUser = () => {
      const requestOptions = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
        body: JSON.stringify({
          idUtente: user.id,
          idOrganization: user.id_organization,
          idDipendente: row.idUser,
        }),
      };

      fetch(config.apiUrl + "users/DeleteDipendente.php", requestOptions)
        .then(() => props.count())
        .then(() => handleCloseDelete());
    };

    const generatePassword = () => {
      var length = 14,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      var newEditForm = { ...editForm };
      newEditForm.password = retVal;
      setEditForm(newEditForm);
    };

    useEffect(() => {
      GetCountry();
    }, []);

    useEffect(() => {
      if (countryUser !== undefined) {
        if (countryUser.label === "Italia") {
          GetRegione();
        } else {
          setRegione([]);
        }
      }
      /*eslint-disable-next-line*/
    }, [countryUser]);

    useEffect(() => {
      if (regionUser !== undefined) {
        if (regionUser.value !== "") {
          GetProvince();
        }
      }
      /*eslint-disable-next-line*/
    }, [regionUser]);

    useEffect(() => {
      if (provinceUser !== undefined) {
        if (provinceUser.value !== "") {
          GetCity();
        }
      }
      /*eslint-disable-next-line*/
    }, [provinceUser]);

    const formik = useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        const formResult = new FormData();
        formResult.append("idDipendente", row.idUser);
        formResult.append("idOrganization", user.id_organization);
        formResult.append("idUser", user.id);
        formResult.append("country", selectedCountry);
        formResult.append("region", selectedRegion);
        formResult.append("province", selectedProvince);
        formResult.append("city", selectedCity);
        for (let value in values) {
          formResult.append(value, values[value]);
        }
        const requestOptions = {
          headers: {
            Authorization:
              "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
          },
          method: "POST",
          body: formResult,
        };
        fetch(config.apiUrl + "users/EditUser.php", requestOptions)
          .then((response) => response.json())
          .then((data) => {
            setShowEdit(false);
            props.handleOpenToast(!showEdit);
            props.setMessage(data[0].message);
            props.setTitle("Modifica profilo terminata");
            setEmailMessage(data[0].emailMessage);
            setEmailAziendaleMessage(data[0].emailAziendaleMessage);
            setPhoneMessage(data[0].phoneMessage);
          })
          .then(() => props.count());
      },
    });

    return (
      <>
        {/* Delete Modal: BEGIN */}

        <Modal centered show={showDelete} onHide={handleCloseDelete}>
          <Modal.Header closeButton>
            <Modal.Title>Elimina {row.nomeCompleto}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Sei sicuro di voler eliminare definitivamente l'utente?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => DeleteUser()}>
              Elimina
            </Button>
            <Button variant="primary" onClick={handleCloseDelete}>
              Annulla
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delete Modal: END */}

        {/* Edit Modal: BEGIN */}

        <Modal centered size="lg" show={showEdit} onHide={handleCloseEdit}>
          <Modal.Header closeButton>
            <Modal.Title>
              Modifica scheda di <b>{row.nomeCompleto}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row">
                <div className="form-group col-6">
                  <label className="col-12">Nome utente</label>
                  <input
                    type="text"
                    name="nome"
                    placeholder="Nome utente..."
                    value={formik.values.nome}
                    {...formik.getFieldProps("nome")}
                    className={`form-control ${getInputClassesForHandle(
                      "nome"
                    )}`}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.nome && formik.errors.nome ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.nome}</div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-6">
                  <label className="col-12">Cognome utente</label>
                  <input
                    type="text"
                    name="cognome"
                    {...formik.getFieldProps("cognome")}
                    placeholder="Cognome utente..."
                    value={formik.values.cognome}
                    className={`form-control ${getInputClassesForHandle(
                      "cognome"
                    )}`}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.cognome && formik.errors.cognome ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.cognome}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-6">
                  <label className="col-12">Email</label>
                  <input
                    type="email"
                    placeholder="Email..."
                    {...formik.getFieldProps("email")}
                    name="email"
                    value={formik.values.email}
                    className={`form-control ${getInputClassesForHandle(
                      "email"
                    )}`}
                    onClick={() => setEmailMessage(false)}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  ) : null}
                  {emailMessage === true ? (
                    <p style={{ color: "red" }}>{message}</p>
                  ) : null}
                </div>
                <div className="form-group col-6">
                  <label className="col-12">Email aziendale</label>
                  <input
                    type="email"
                    {...formik.getFieldProps("emailAziendale")}
                    placeholder="Email aziendale..."
                    name="emailAziendale"
                    value={formik.values.emailAziendale}
                    className={`form-control ${getInputClassesForHandle(
                      "emailAziendale"
                    )}`}
                    onClick={() => setEmailAziendaleMessage(false)}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.emailAziendale &&
                  formik.errors.emailAziendale ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.emailAziendale}
                      </div>
                    </div>
                  ) : null}
                  {emailAziendaleMessage === true ? (
                    <p style={{ color: "red" }}>{message}</p>
                  ) : null}
                </div>
                <div className="form-group col-6">
                  <label className="col-12 mt-2">Anno di nascita</label>
                  <input
                    type="date"
                    placeholder="Anno di nascita..."
                    name="birthday"
                    value={formik.values.birthday}
                    className="form-control"
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label className="col-12 mt-2">Password</label>
                  <div className="input-group mb-2">
                    <div className="input-group-prepend">
                      <div
                        onClick={() => generatePassword()}
                        className="btn generatePassword input-group-text"
                      >
                        Genera
                      </div>
                    </div>
                    <input
                      type="text"
                      placeholder="Genera nuova password..."
                      value={formik.values.password}
                      className="form-control"
                      style={{ float: "left" }}
                      name="password"
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                <div className="form-group col-6">
                  <label className="col-12 mt-2">Numero telefono</label>
                  <input
                    type="text"
                    placeholder="Numero telefono..."
                    name="phone"
                    value={formik.values.phone}
                    {...formik.getFieldProps("phone")}
                    className={`form-control ${getInputClassesForHandle(
                      "phone"
                    )}`}
                    onClick={() => setPhoneMessage(false)}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.phone}</div>
                    </div>
                  ) : null}
                  {phoneMessage === true ? (
                    <p style={{ color: "red" }}>{message}</p>
                  ) : null}
                </div>
                <Form.Group className="col-6">
                  <Form.Label className="col-12">Nazione</Form.Label>
                  <Select
                    value={
                      countryUser !== undefined
                        ? {
                            value: countryUser.value,
                            label: countryUser.label,
                          }
                        : ""
                    }
                    options={country}
                    onChange={(e) => {
                      setCountryUser(e);
                      setSelectedCountry(e.label);
                      if (e.label !== "Italia") {
                        setSelectedRegion("Estero");
                        setSelectedProvince("Estero");
                        setSelectedCity("Estero");
                      }
                    }}
                    placeholder="Seleziona nazione..."
                  />
                </Form.Group>

                <Form.Group className="col-6">
                  <Form.Label className="col-12">Regione</Form.Label>
                  <Select
                    value={
                      regionUser !== undefined
                        ? {
                            value: regionUser.value,
                            label: regionUser.label,
                          }
                        : ""
                    }
                    options={regione}
                    onChange={(e) => {
                      setRegionUser(e);
                      setSelectedRegion(e.label);
                      setSelectedCity("");
                      setCityUser({ label: "", value: "" });
                      setCity([]);
                      setSelectedProvince("");
                      setProvinceUser({ label: "", value: "" });
                    }}
                    placeholder="Seleziona regione..."
                    isDisabled={selectedCountry !== "Italia" ? true : false}
                  />
                </Form.Group>

                <Form.Group className="col-6">
                  <Form.Label className="col-12">Provincia</Form.Label>
                  <Select
                    value={
                      provinceUser !== undefined
                        ? {
                            value: provinceUser.value,
                            label: provinceUser.label,
                          }
                        : ""
                    }
                    options={province}
                    onChange={(e) => {
                      setProvinceUser(e);
                      setSelectedProvince(e.label);
                    }}
                    placeholder="Seleziona provincia..."
                    isDisabled={
                      selectedRegion === "" || selectedCountry !== "Italia"
                        ? true
                        : false
                    }
                  />
                </Form.Group>

                <Form.Group className="col-6">
                  <Form.Label className="col-12">Comune</Form.Label>
                  <Select
                    value={
                      cityUser !== undefined
                        ? {
                            value: cityUser.value,
                            label: cityUser.label,
                          }
                        : ""
                    }
                    options={city}
                    onChange={(e) => {
                      setCityUser(e);
                      setSelectedCity(e.label);
                    }}
                    placeholder="Seleziona comune..."
                    isDisabled={
                      selectedProvince === "" || selectedCountry !== "Italia"
                        ? true
                        : false
                    }
                  />
                </Form.Group>
                <div className="form-group col-6">
                  <label className="col-12 mt-2">CAP</label>
                  <input
                    type="text"
                    placeholder="CAP..."
                    name="zip"
                    value={formik.values.zip}
                    {...formik.getFieldProps("zip")}
                    className={`form-control ${getInputClassesForHandle(
                      "zip"
                    )}`}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.zip && formik.errors.zip ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.zip}</div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-6">
                  <label className="col-12 mt-2">Indirizzo</label>
                  <input
                    type="text"
                    placeholder="Indirizzo..."
                    name="address"
                    value={formik.values.address}
                    className="form-control"
                    onChange={formik.handleChange}
                  />
                </div>
                <div className="form-group col-6">
                  <label className="col-12 mt-2">Partita IVA</label>
                  <input
                    type="text"
                    name="vatNumber"
                    placeholder="Partita IVA..."
                    value={formik.values.vatNumber}
                    {...formik.getFieldProps("vatNumber")}
                    className={`form-control ${getInputClassesForHandle(
                      "vatNumber"
                    )}`}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.vatNumber && formik.errors.vatNumber ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.vatNumber}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group col-6">
                  <label className="col-12 mt-2">IBAN</label>
                  <input
                    type="text"
                    placeholder="IBAN..."
                    value={formik.values.iban}
                    name="iban"
                    className={`form-control ${getInputClassesForHandle(
                      "iban"
                    )}`}
                    {...formik.getFieldProps("iban")}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.iban && formik.errors.iban ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.iban}</div>
                    </div>
                  ) : null}
                </div>
                <div className="col-6 form-group">
                  <label className="col-12">Ruolo</label>
                  <select
                    onChange={formik.handleChange}
                    value={formik.values.idRole}
                    name="idRole"
                    className="form-control"
                  >
                    <option>Seleziona ruolo</option>
                    <option value={roles.dipendente}>Dipendente</option>
                    <option value={roles.hr}>HR - Supervisor</option>
                    <option value={roles.admin}>Admin</option>
                  </select>
                </div>
                <div className="col-6 form-group">
                  <label className="col-12">Dipartimento</label>
                  <select
                    onChange={formik.handleChange}
                    value={formik.values.idDepartment}
                    name="idDepartment"
                    className="form-control"
                  >
                    <option>Seleziona dipartimento</option>
                    {props.departments.map((e, key) => (
                      <option value={e.idDepartment} key={key}>
                        {e.nameDepartment}
                      </option>
                    ))}
                  </select>
                </div>
                {props.customfield !== []
                  ? props.customfield.map((e, key) => {
                      return (
                        <div className="col-6 form-group">
                          <label className="col-12">{e.nomeCampo}</label>
                          <input
                            type="text"
                            className="form-control"
                            key={e.slug}
                            name={e.slug}
                            placeholder={"Inserire " + e.nomeCampo}
                            onChange={formik.handleChange}
                            value={formik.values[e.slug]}
                          />
                        </div>
                      );
                    })
                  : null}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={formik.handleSubmit}>
              Salva
            </Button>
            <Button variant="secondary" onClick={handleCloseEdit}>
              Annulla
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Edit Modal: END */}

        {/* Riga dipendente: BEGIN */}

        <div className="row">
          <div className="col-sm-3">
            <p className="mb-0 text-muted">EMAIL:</p>
          </div>
          <div className="col-sm-9 text-secondary">{row.email}</div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-3">
            <p className="mb-0 text-muted">EMAIL AZIENDALE:</p>
          </div>
          <div className="col-sm-9 text-secondary">{row.emailAziendale}</div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-3">
            <p className="mb-0 text-muted">TELEFONO:</p>
          </div>
          <div className="col-sm-9 text-secondary">{row.phone}</div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-3">
            <p className="mb-0 text-muted">DIPARTIMENTO:</p>
          </div>
          <div className="col-sm-9 text-secondary">
            {row.department === "-" ? row.departmentFather : row.department}
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-3">
            <p className="mb-0 text-muted">RUOLO:</p>
          </div>
          {console.log(row)}
          <div className="col-sm-9 text-secondary">
            {parseInt(row.idRole) === roles.dipendente ? "Dipendente" : null}
            {parseInt(row.idRole) === roles.hr ? "HR - Supervisor" : null}
            {parseInt(row.idRole) === roles.admin ? "Admin" : null}
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-sm-12">
            <button
              className="btn btn-danger float-right p-2"
              onClick={() => handleShowDelete()}
            >
              Elimina
            </button>
            <button
              onClick={() => {
                handleShowEdit();
                //setValues(handleCustomField());
              }}
              className="btn btn-primary float-right p-2 mr-2"
            >
              Modifica
            </button>
          </div>
        </div>
        {/* Riga dipendente: END */}
      </>
    );
  };

  const [columns] = useState([
    { name: "idUser", title: "ID" },
    { name: "nome", title: "Nome" },
    { name: "cognome", title: "Cognome" },
    { name: "email", title: "E-mail" },
    /* { name: "departmentFather", title: "Dipartimento" },
    { name: "department", title: "Sotto dipartimento" },
    { name: "role", title: "Ruolo" }, */
  ]);

  const [rows, setRows] = useState(props.data);

  useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  const pagingPanelMessages = {
    showAll: "Tutti",
    rowsPerPage: "Colonne per pagina",
    info: "Da {from} a {to} ({count} elementi)",
  };

  const tableMessages = {
    noData: "Nessun dato",
  };

  //page sizing
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes] = useState([10, 25, 50, 0]);

  //sorting
  const [sorting, setSorting] = useState([
    { columnName: "nome", direction: "asc" },
  ]);

  //column visibility
  const [defaultHiddenColumnNames] = useState(["idUser"]);

  return (
    <div className="card">
      <Grid rows={rows} columns={columns}>
        <SortingState sorting={sorting} onSortingChange={setSorting} />
        <IntegratedSorting />
        <RowDetailState />
        <FilteringState />
        <IntegratedFiltering />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
        <IntegratedPaging />
        <Table messages={tableMessages} tableComponent={TableComponent} />
        <TableHeaderRow showSortingControls />
        <TableColumnVisibility
          defaultHiddenColumnNames={defaultHiddenColumnNames}
        />
        <TableColumnVisibility
          defaultHiddenColumnNames={defaultHiddenColumnNames}
        />
        <TableRowDetail contentComponent={RowDetail} />
        <Toolbar />
        <ColumnChooser />
        <PagingPanel messages={pagingPanelMessages} pageSizes={pageSizes} />
        <TableFilterRow messages={filterRowMessages} />
      </Grid>
    </div>
  );
};

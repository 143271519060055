import React from "react";
// import Progress from '../widgets/mixed/Progress';
import {
  MixedWidget1,
  /* MixedWidget14, */
  ListsWidget9,
  /* AdvanceTablesWidget2, */
  AdvanceTablesWidget4,
  /* TaskObiettivi, */
} from "../widgets";
import { StatusBarObiettivi } from "../widgets/mixed/StatusBarObiettivi";

export function Demo1Dashboard() {
  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-sm-12">
          <MixedWidget1 className="card-stretch gutter-b" />
        </div>
      </div>

      {/*           <div className="col-sm"> */}
      {/*  <StatusBarObiettivi className="card-stretch gutter-b" /> */}

      {/*         <div className="col-sm">
          <TaskObiettivi className="card-stretch gutter-b" />
        </div> */}
      <div className="col-sm">
        {/* avvisi */}
        <ListsWidget9 className="card-stretch gutter-b" />
      </div>

      {/*       </div> */}
      {/* prossimi eventi */}
      {/*  <div className="container">
        <div className="row">
          <div className="col-sm">
            <AdvanceTablesWidget2 className="card-stretch gutter-b" />
          </div>
        </div>
      </div> */}

      {/* widget con informazioni dipendenti -> stipendi ecc... */}
      {/* <AdvanceTablesWidget4 className="card-stretch gutter-b" /> */}

      {/* <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
          <ListsWidget3 className="card-stretch gutter-b" />
        </div> */}
    </>
  );
}

import React from "react";
import { useSubheader } from "../../_metronic/layout";
/*eslint-disable */

export const BustePaga = () => {

  const suhbeader = useSubheader();
  suhbeader.setTitle("Buste Paga");

  return (
    <div className={`card card-custom`}>
      {/* Head */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">Buste Paga <small>(Tutto il team)</small></span>
        </h3>
        <div style={{float:"right"}} className="card-toolbar">
          <a href="#" className="btn btn-primary font-weight-bolder font-size-sm mr-3">Inserisci Busta paga</a>
          <a href="#" className="btn btn-primary primary-weight-bolder font-size-sm mr-3">Scarica Report</a>
        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-0 pb-3">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-7" style={{ minWidth: "250px" }}><span className="text-dark-75">Agente</span></th>
                  <th style={{ minWidth: "100px" }}>Data</th>
                  <th style={{ minWidth: "100px" }}>N°</th>
                  <th style={{ minWidth: "100px" }}>Importo</th>
                  <th style={{ minWidth: "130px" }}>Periodo</th>
                  <th style={{ minWidth: "80px" }} />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Mario
                        Rossi</a>
                        <span className="text-muted font-weight-bold d-block">Team Director</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      08/03/2021
                    </span>
                    <span className="text-success font-weight-bold">
                      Disponiibile
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      N° 123
                    </span>
                    <span className="text-success font-weight-bold">
                      Verificato
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      1200€
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      Febbraio 2021
                    </span>
                  </td>
                  <td className="pr-0 text-right">
                    <a href="#" className="btn btn-light-primary font-weight-bolder font-size-sm">Scarica</a>
                  </td>
                </tr>
                <tr>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Mario
                        Loffredo</a>
                        <span className="text-muted font-weight-bold d-block">Mobile Specialist</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      02/03/2021
                    </span>
                    <span className="text-success font-weight-bold">
                      Disponiibile
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      N° 101
                    </span>
                    <span className="text-success font-weight-bold">
                      Verificato
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      1500€
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      Febbraio 2021
                    </span>
                  </td>
                  <td className="pr-0 text-right">
                    <a href="#" className="btn btn-light-primary font-weight-bolder font-size-sm">Scarica</a>
                  </td>
                </tr>
                <tr>
                <td className="pl-0 py-8">
                  <div className="d-flex align-items-center">
                    <div>
                      <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Michele
                          Loffredo</a>
                      <span className="text-muted font-weight-bold d-block">Mobile Specialist</span>
                    </div>
                  </div>
                </td>
                <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      05/03/2021
                    </span>
                  <span className="text-success font-weight-bold">
                      Disponiibile
                    </span>
                </td>
                <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      N° 123
                    </span>
                  <span className="text-success font-weight-bold">
                      Verificato
                    </span>
                </td>
                <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      1000€
                    </span>
                </td>
                <td>
                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      Febbraio 2021
                    </span>
                </td>
                <td className="pr-0 text-right">
                  <a href="#" className="btn btn-light-primary font-weight-bolder font-size-sm">Scarica</a>
                </td>
              </tr>
              <tr>
                <td className="pl-0 py-8">
                  <div className="d-flex align-items-center">
                    <div>
                      <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Nicola
                          De Simone</a>
                      <span className="text-muted font-weight-bold d-block">Mobile Guide Specialist</span>
                    </div>
                  </div>
                </td>
                <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      01/03/2021
                    </span>
                  <span className="text-success font-weight-bold">
                      Disponiibile
                    </span>
                </td>
                <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      N° 129
                    </span>
                  <span className="text-success font-weight-bold">
                      Verificato
                    </span>
                </td>
                <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      1500€
                    </span>
                </td>
                <td>
                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      Febbraio 2021
                    </span>
                </td>
                <td className="pr-0 text-right">
                  <a href="#" className="btn btn-light-primary font-weight-bolder font-size-sm">Scarica</a>
                </td>
              </tr>
              <tr>
                <td className="pl-0 py-8">
                  <div className="d-flex align-items-center">
                    <div>
                      <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Antonio
                          Broegg</a>
                      <span className="text-muted font-weight-bold d-block">Mobile Specialist</span>
                    </div>
                  </div>
                </td>
                <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      10/03/2021
                    </span>
                  <span className="text-success font-weight-bold">
                      Disponiibile
                    </span>
                </td>
                <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      N° 140
                    </span>
                  <span className="text-success font-weight-bold">
                      Verificato
                    </span>
                </td>
                <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      1600€
                    </span>
                </td>
                <td>
                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      Febbraio 2021
                    </span>
                </td>
                <td className="pr-0 text-right">
                  <a href="#" className="btn btn-light-primary font-weight-bolder font-size-sm">Scarica</a>
                </td>
              </tr>
              <tr>
                <td className="pl-0 py-8">
                  <div className="d-flex align-items-center">
                    <div>
                      <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Luigi
                          Mazza</a>
                      <span className="text-muted font-weight-bold d-block">Team Specialist</span>
                    </div>
                  </div>
                </td>
                <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      15/03/2021
                    </span>
                  <span className="text-success font-weight-bold">
                      Disponiibile
                    </span>
                </td>
                <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      N° 127
                    </span>
                  <span className="text-success font-weight-bold">
                      Verificato
                    </span>
                </td>
                <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      1500€
                    </span>
                </td>
                <td>
                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      Febbraio 2021
                    </span>
                </td>
                <td className="pr-0 text-right">
                  <a href="#" className="btn btn-light-primary font-weight-bolder font-size-sm">Scarica</a>
                </td>
              </tr>
            
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

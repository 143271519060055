import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import config from "../../../../config/config";
import moment from "moment";

export default function ModalRichiesta(props) {
  const [file, setFile] = useState();
  const { user } = useSelector((state) => state.auth);

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const eventSchema = Yup.object().shape({
    startDate: Yup.date("Inserire una data valida").required(
      "Inserire una data valida"
    ),
    allDay: Yup.boolean(),
    type: Yup.string("Selezionare tipo permesso").required(
      "Selezionare tipo permesso"
    ),
    startTime: Yup.string("Inserire un orario").notRequired(
      "Inserire un orario valido"
    ),
    endDate: Yup.string("Inserire una data valida").when("allDay", {
      is: false,
      then: Yup.string().required("Inserire data fine evento"),
    }),
    endTime: Yup.string("Inserire un orario valido").when("allDay", {
      is: false,
      then: Yup.string("Inserire un orario valido").required(
        "Inserire orario di fine evento"
      ),
    }),
    protocollo: Yup.string("Inserire numero protocollo").notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      type: "",
      startDate: moment(props.startDate).format("YYYY-MM-DD"),
      startTime: moment(new Date()).format("HH:mm"),
      endDate: moment(props.endDate).format("YYYY-MM-DD"),
      endTime: moment(new Date()).format("HH:mm"),
      protocollo: "",
      file: false,
      allDay: false,
    },
    validationSchema: eventSchema,
    onSubmit: (values) => {
      const data = new FormData();
      data.append("file", file);
      data.append("type", values.type);
      data.append("startDate", values.startDate);
      data.append("startTime", values.allDay ? "09:00" : values.startTime);
      data.append("endDate", values.allDay ? values.startDate : values.endDate);
      data.append("endTime", values.allDay ? "09:00" : values.endTime);
      data.append("allDay", values.allDay ? 1 : 0);
      data.append("protocollo", values.protocollo);
      data.append("isFile", values.file);
      data.append("idUser", user.id);
      data.append("idDepartment", user.idDepartment);
      data.append("idOrganization", user.id_organization);
      data.append("idDepartment", user.idDepartment);
      data.append("firstname", user.firstname);
      data.append("lastname", user.lastname);
      const options = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
        body: data,
      };
      fetch(config.apiUrl + "calendar/RichiediPermesso.php", options).then(() =>
        props.setCount(props.count + 1)
      );
      props.handleClose();
    },
  });

  return (
    <Modal backdrop="static" show={props.show} onHide={props.handleClose}>
      <Modal.Header style={{ backgroundColor: "#caf0f8" }} closeButton>
        <Modal.Title>Richiedi permesso</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Form.Group className="col-6">
              <Form.Label className="col-12">
                Seleziona tipo permesso
              </Form.Label>
              <select
                className={`form-control ${getInputClasses("type")}`}
                value={formik.values.type}
                name="type"
                onChange={formik.handleChange}
                {...formik.getFieldProps("type")}
              >
                <option value={0}>Seleziona tipo permesso</option>
                <option value="centoquattro">Permesso - 104</option>
                <option value="malattia">Malattia</option>
                <option value="rol">Permesso - ROL</option>
                <option value="retribuito">Permesso - Retribuito</option>
              </select>
              {formik.touched.type && formik.errors.type ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.type}</div>
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className="col-6">
              <Form.Label className="col-12">Tutto il giorno?</Form.Label>
              <label className="checkbox mt-2">
                <input
                  type="checkbox"
                  color="primary"
                  className="checkbox-inline"
                  checked={formik.values.allDay}
                  onChange={formik.handleChange}
                  name="allDay"
                />
                <span style={{ marginRight: "5px" }}></span>
                {formik.values.allDay ? "Si" : "No"}
              </label>
            </Form.Group>
            <Form.Group className={formik.values.allDay ? "col-12" : "col-6"}>
              <Form.Label className="col-12">Data inizio permesso</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                value={formik.values.startDate}
                onChange={formik.handleChange}
                className={getInputClasses("startDate")}
                {...formik.getFieldProps("startDate")}
              />
              {formik.touched.startDate && formik.errors.startDate ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.startDate}</div>
                </div>
              ) : null}
            </Form.Group>
            {!formik.values.allDay ? (
              <>
                <Form.Group className="col-6">
                  <Form.Label className="col-12">
                    Ora inizio permesso
                  </Form.Label>
                  <Form.Control
                    type="time"
                    name="startTime"
                    value={formik.values.startTime}
                    onChange={formik.handleChange}
                    className={getInputClasses("startTime")}
                    {...formik.getFieldProps("startTime")}
                  />
                  {formik.touched.startTime && formik.errors.startTime ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.startTime}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group className="col-6">
                  <Form.Label className="col-12">Data fine permesso</Form.Label>
                  <Form.Control
                    type="date"
                    name="endDate"
                    min={moment(formik.values.startDate).format("YYYY-MM-DD")}
                    value={formik.values.endDate}
                    onChange={formik.handleChange}
                    className={getInputClasses("endDate")}
                    {...formik.getFieldProps("endDate")}
                  />
                  {formik.touched.endDate && formik.errors.endDate ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.endDate}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group className="col-6">
                  <Form.Label className="col-12">Ora fine permesso</Form.Label>
                  <Form.Control
                    type="time"
                    name="endTime"
                    value={formik.values.endTime}
                    onChange={formik.handleChange}
                    className={getInputClasses("endTime")}
                    {...formik.getFieldProps("endTime")}
                  />
                  {formik.touched.endTime && formik.errors.endTime ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.endTime}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>{" "}
              </>
            ) : null}

            <Form.Group className="col-6">
              <Form.Label className="col-12">Numero protocollo</Form.Label>
              <Form.Control
                type="text"
                name="protocollo"
                value={formik.values.protocollo}
                placeholder="Numero protocollo..."
                onChange={formik.handleChange}
                className={getInputClasses("protocollo")}
                {...formik.getFieldProps("protocollo")}
              />
              {formik.touched.protocollo && formik.errors.protocollo ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.protocollo}
                  </div>
                </div>
              ) : null}
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Annulla
        </Button>
        <Button variant="primary" onClick={formik.handleSubmit}>
          Invia richiesta
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

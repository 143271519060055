/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import config, { roles } from "../../../../config/config";
import { ModalRequest } from "../mixed/ModalRequest";
import { List } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Tabs, Tab } from "react-bootstrap";
import { Skeleton } from "@material-ui/lab";
import { toAbsoluteUrl } from "../../../_helpers";
/*eslint-disable */

export const ListsRichieste = ({ className }) => {
  const { user } = useSelector((state) => state.auth);
  const [richieste, setRichieste] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedNotification, setSelect] = useState({ type: "" });
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  const handleClose = () => setShow(false);
  function handleShow(notifiche) {
    setSelect(notifiche);
    setShow(true);
  }

  const requestOptions = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      idUser: user.id,
      idOrganization: user.id_organization,
      idRole: user.id_role,
      idDepartment: user.idDepartment,
    }),
  };

  const GetRequests = () => {
    setLoading(true);
    fetch(config.apiUrl + "calendar/GetRequests.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setRichieste(data))
      .then(() => setLoading(false));
  };

  useEffect(() => {
    GetRequests();
  }, [count]);

  var status;
  function handleStatus(notifica) {
    if (parseInt(notifica.status) === 0) {
      status = "Attesa";
      return "label label-lg label-light-warning label-inline font-weight-bold py-4";
    } else if (parseInt(notifica.status) === 1) {
      status = "Approvata";
      return "label label-lg label-light-success label-inline font-weight-bold py-4";
    } else if (parseInt(notifica.status) === -1) {
      status = "Rifiutata";
      return "label label-lg label-light-danger label-inline font-weight-bold py-4";
    }
  }

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-dashboard border-0">
          <h3 className="card-title font-weight-bolder text-dark">Richieste</h3>
        </div>

        {/* Body */}
        <div className="card-body pt-0">
          <Tabs defaultActiveKey="permessi">
            <Tab eventKey="permessi" title="Permessi">
              {!loading ? (
                <List>
                  {richieste.map((permesso, key) => {
                    if (permesso["type"].includes("permesso")) {
                      return (
                        <div
                          key={key}
                          className="d-flex flex-wrap align-items-center justify-content-between w-100"
                        >
                          <div className="d-flex flex-column align-items-cente py-2 w-75">
                            {isNaN(permesso.extra) ? (
                              <a
                                href={toAbsoluteUrl(permesso.extra)}
                                className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                                download
                              >
                                Richiesta {permesso.type} - (download
                                certificato)
                              </a>
                            ) : (
                              <p className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1">
                                Richiesta {permesso.type} - N° protocollo{" "}
                                {permesso.extra}
                              </p>
                            )}
                            <span className="text-muted font-weight-bold">
                              Richiesto il: {permesso.dataRichiesta} <br />{" "}
                              {permesso.allDay === 1
                                ? "Per la data " + permesso.startDate
                                : "Per le date: " +
                                  permesso.startDate +
                                  "-" +
                                  permesso.endDate}
                              <br />
                              {parseInt(user.id_role) === roles.admin ||
                              parseInt(user.id_role) === roles.hr
                                ? "Effettuata da " + permesso.nomeDipendente
                                : null}
                            </span>
                          </div>
                          {parseInt(user.id_role) === roles.admin ||
                          parseInt(user.id_role) === roles.hr ? (
                            <Button onClick={() => handleShow(permesso)}>
                              <span className={handleStatus(permesso)}>
                                {status}
                              </span>
                            </Button>
                          ) : (
                            <span className={handleStatus(permesso)}>
                              {status}
                            </span>
                          )}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </List>
              ) : (
                <Skeleton className="mt-4" variant="text" animation="wave" />
              )}
            </Tab>
            <Tab eventKey="straordinari" title="Straordinari">
              {!loading ? (
                <List>
                  {richieste.map((straordinario, key) => {
                    if (straordinario["type"].includes("Straordinario")) {
                      return (
                        <div
                          key={key}
                          className="d-flex flex-wrap align-items-center justify-content-between w-100"
                        >
                          <div className="d-flex flex-column align-items-cente py-2 w-75">
                            <p className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1">
                              Richiesta {straordinario.extra} minuti
                            </p>

                            <span className="text-muted font-weight-bold">
                              Richiesto il: {straordinario.dataRichiesta} <br />{" "}
                              {"Per la data " + straordinario.startDate}
                              <br />
                              {parseInt(user.id_role) === roles.admin ||
                              parseInt(user.id_role) === roles.hr
                                ? "Effettuata da " +
                                  straordinario.nomeDipendente
                                : null}
                            </span>
                          </div>
                          {parseInt(user.id_role) === roles.admin ||
                          parseInt(user.id_role) === roles.hr ? (
                            <Button onClick={() => handleShow(straordinario)}>
                              <span className={handleStatus(straordinario)}>
                                {status}
                              </span>
                            </Button>
                          ) : (
                            <span className={handleStatus(straordinario)}>
                              {status}
                            </span>
                          )}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </List>
              ) : (
                <Skeleton className="mt-4" variant="text" animation="wave" />
              )}
            </Tab>
            <Tab eventKey="ferie" title="Ferie">
              {!loading ? (
                <List>
                  {richieste.map((feria, key) => {
                    if (feria["type"].includes("Ferie")) {
                      return (
                        <div
                          key={key}
                          className="d-flex flex-wrap align-items-center justify-content-between w-100"
                        >
                          <div className="d-flex flex-column align-items-cente py-2 w-75">
                            <p className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1">
                              Richiesta {feria.extra}
                            </p>

                            <span className="text-muted font-weight-bold">
                              Richiesto il: {feria.dataRichiesta} <br />{" "}
                              {"Da " + feria.startDate + " a " + feria.endDate}
                              <br />
                              {parseInt(user.id_role) === roles.admin ||
                              parseInt(user.id_role) === roles.hr
                                ? "Effettuata da " + feria.nomeDipendente
                                : null}
                            </span>
                          </div>
                          {parseInt(user.id_role) === roles.admin ||
                          parseInt(user.id_role) === roles.hr ? (
                            <Button onClick={() => handleShow(feria)}>
                              <span className={handleStatus(feria)}>
                                {status}
                              </span>
                            </Button>
                          ) : (
                            <span className={handleStatus(feria)}>
                              {status}
                            </span>
                          )}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </List>
              ) : (
                <Skeleton className="mt-4" variant="text" animation="wave" />
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
      <ModalRequest
        notifica={selectedNotification}
        handleShow={handleShow}
        handleClose={handleClose}
        count={count}
        setCount={setCount}
        show={show}
      />
    </>
  );
};

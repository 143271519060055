/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Form,
  Card,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
} from "react-bootstrap";
import config, { roles } from "../../../../config/config";
import { CSVLink } from "react-csv";
import { Skeleton } from "@material-ui/lab";
import { DataTypeProvider } from "@devexpress/dx-react-grid";
import Chip from "@material-ui/core/Chip";
import {
  Grid,
  Table,
  PagingPanel,
  TableHeaderRow,
  TableColumnVisibility,
  SearchPanel,
  Toolbar,
} from "@devexpress/dx-react-grid-bootstrap4";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  SearchState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
/*eslint-disable */
export function TabellaPresenze(props) {
  const { user } = useSelector((state) => state.auth);

  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const handleOpen = (value) => {
    setShow(true);
    setValue(value);
  };
  const handleClose = () => {
    setShow(false);
    setValue([]);
  };
  const [showDelete, setShowDelete] = useState(false);
  const handleOpenDelete = (value) => {
    setShowDelete(true);
    setValue(value);
  };
  const handleCloseDelete = () => {
    setShowDelete(false);
    setValue([]);
  };
  const [showAdd, setShowAdd] = useState(false);
  const handleOpenAdd = () => setShowAdd(true);
  const handleCloseAdd = () => setShowAdd(false);
  const [value, setValue] = useState([]);
  const [loadingTab, setLoadingTab] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingDepartments, setLoadingDepartments] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageSizes] = useState([10, 25, 50, 100, 0]);
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [selectedUser, setSelectedUser] = useState(user.id);
  const [selectedDepartment, setSelectedDepartment] = useState(
    user.idDepartment
  );
  const [presenzaCol] = useState(["type"]);
  const [buttonCol] = useState(["button"]);
  const [hiddenColumnNames] = useState(["id"]);
  const [searchValue, setSearchState] = useState("");

  const requestOptions = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      dipendente: user.id,
    }),
  };

  const superOptions = {
    headers: {
      Authorization:
        "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
    },
    method: "POST",
    body: JSON.stringify({
      idUser: user.id,
      idRole: user.id_role,
      idDepartment: selectedDepartment,
      idOrganization: user.id_organization,
      ownDepartment: user.idDepartment,
      dipendente: selectedUser,
    }),
  };

  const GetDepartments = () => {
    setLoadingDepartments(true);
    fetch(config.apiUrl + "calendar/GetDepartments.php", superOptions)
      .then((response) => response.json())
      .then((data) => setDepartments(data))
      .then(() => setLoadingDepartments(false));
  };

  const GetUsers = () => {
    setLoadingUsers(true);
    fetch(config.apiUrl + "calendar/GetUsers.php", superOptions)
      .then((response) => response.json())
      .then((data) => setUsers(data))
      .then(() => setLoadingUsers(false));
  };

  const GetPresenzeTab = () => {
    setLoadingTab(true);
    fetch(
      config.apiUrl + "calendar/GetPresenzeTab.php",
      parseInt(user.id_role) === roles.admin ||
        parseInt(user.id_role) === roles.hr
        ? superOptions
        : requestOptions
    )
      .then((response) => response.json())
      .then((data) => setData(data))
      .then(() => setLoadingTab(false));
  };

  useEffect(() => {
    if (parseInt(user.id_role) === roles.admin) {
      GetDepartments();
    }
    if (parseInt(user.id_role) === roles.hr) {
      GetUsers();
    }
  }, []);

  useEffect(() => {
    if (parseInt(user.id_role) === roles.admin) {
      GetUsers();
    }
  }, [selectedDepartment]);

  useEffect(() => {
    GetPresenzeTab();
  }, [selectedUser, count]);

  const TableComponent = ({ ...restProps }) => (
    <Table.Table {...restProps} className="table-striped" />
  );

  const PresenzaFormatter = ({ value }) => {
    var color;
    switch (value) {
      case "Presenza":
        color = "#55a630";
        break;
      case "Permesso":
        color = "#ffaa00";
        break;
      case "Ferie":
        color = "#8f2d56";
        break;
      case "Assenza":
        color = "#c1121f";
        break;
      case "Straordinario":
        color = "#C6D8D3";
        break;
      default:
        color = "#8a817c";
    }
    return (
      <>
        <Chip
          size="large"
          label={value}
          icon={
            <i style={{ color: color }} className="fas fa-circle fa-sm"></i>
          }
        />
      </>
    );
  };

  const renderEdit = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Modifica
    </Tooltip>
  );

  const renderDelete = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Elimina
    </Tooltip>
  );

  const editColum = ({ value }) => (
    <>
      <OverlayTrigger placement="top" overlay={renderEdit}>
        <i
          onClick={() => handleOpen(value)}
          style={{ cursor: "pointer", color: "#1565c0" }}
          className="fas fa-pen ml-3 mr-3 mt-1"
        ></i>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={renderDelete}>
        <i
          onClick={() => handleOpenDelete(value)}
          style={{ cursor: "pointer", color: "#bb3e03" }}
          className="fas fa-trash-alt ml-3 mt-1"
        ></i>
      </OverlayTrigger>
    </>
  );

  const ModalEdit = () => {
    const getInputClasses = (fieldname) => {
      if (formik.touched[fieldname] && formik.errors[fieldname]) {
        return "is-invalid";
      }

      if (formik.touched[fieldname] && !formik.errors[fieldname]) {
        return "is-valid";
      }

      return "";
    };

    const schema = Yup.object({
      type: Yup.string("Inserire il tipo di gestione").required(
        "Inserire il tipo di gestione"
      ),
      startDate: Yup.date("Inserire una data valida").required(
        "Inserire la data di inizio gestione"
      ),
      endDate: Yup.date("Inserire una data valida")
        .notRequired()
        .when(
          "startDate",
          (startDate, schema) => startDate && schema.min(startDate),
          "La data finale non può essere precedente a quella iniziale"
        ),
      startTime: Yup.string("Inserire l'ora di inizio").notRequired(),
      endTime: Yup.string("Inserire un orario di fine").notRequired(),
    });

    const formik = useFormik({
      initialValues: {
        type: value.type,
        startDate: value.startDate,
        startTime: value.startTime,
        allDay: value.allDay,
        endDate: value.endDate,
        endTime: value.endTime,
      },
      validationSchema: schema,
      enableReinitialize: true,
      onSubmit: (values) => {
        const options = {
          headers: {
            Authorization:
              "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
          },
          method: "POST",
          body: JSON.stringify({
            idPresenza: value.id,
            type: values.type,
            startDate: values.startDate,
            startTime: values.startTime,
            endDate: values.endDate,
            endTime: values.endTime,
          }),
        };
        fetch(config.apiUrl + "calendar/EditGestione.php", options).then(() =>
          setCount(count + 1)
        )
        .then(()=>props.setGenCount(props.genCount + 1));
        handleClose();
      },
    });

    return (
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header>
          <Modal.Title>Modifica "{value.type}"</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row className="col-12">
              <Form.Group
                className={
                  formik.values.type !== "Presenza" ? "col-6" : "col-12"
                }
              >
                <Form.Label className="col-12">Gestione</Form.Label>
                <select
                  className="form-control"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  name="type"
                >
                  <option value="Presenza">Presenza</option>
                  <option value="Assenza">Assenza</option>
                  <option value="Permesso">Permesso</option>
                  <option value="Ferie">Ferie</option>
                </select>
                {formik.touched.type && formik.errors.type ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.type}</div>
                  </div>
                ) : null}
              </Form.Group>
              {formik.values.type !== "Presenza" ? (
                <Form.Group className="col-6">
                  <Form.Label className="col-12">Intero giorno?</Form.Label>
                  <label className="checkbox mt-2">
                    <input
                      type="checkbox"
                      color="primary"
                      className="checkbox-inline"
                      checked={
                        formik.values !== "Presenza"
                          ? formik.values.allDay
                          : false
                      }
                      onChange={formik.handleChange}
                      name="allDay"
                    />
                    <span style={{ marginRight: "5px" }}></span>
                    {formik.values.allDay ? "Si" : "No"}
                  </label>
                </Form.Group>
              ) : null}
              <Form.Group className="col-6">
                <Form.Label className="col-12">Modifica data inizio</Form.Label>
                <Form.Control
                  type="date"
                  value={formik.values.startDate}
                  name="startDate"
                  onChange={formik.handleChange}
                  className={getInputClasses("startDate")}
                />
                {formik.touched.startDate && formik.errors.startDate ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.startDate}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="col-6">
                <Form.Label className="col-12">Modifica ora inizio</Form.Label>
                <Form.Control
                  type="time"
                  value={formik.values.allDay ? "" : formik.values.startTime}
                  name="startTime"
                  onChange={formik.handleChange}
                  className={getInputClasses("startTime")}
                  disabled={formik.values.allDay}
                />
                {formik.touched.startTime && formik.errors.startTime ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.startTime}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="col-6">
                <Form.Label className="col-12">Modifica data fine</Form.Label>
                <Form.Control
                  type="date"
                  value={formik.values.allDay ? "" : formik.values.endDate}
                  name="endDate"
                  onChange={formik.handleChange}
                  className={getInputClasses("endDate")}
                  disabled={formik.values.allDay}
                />
                {formik.touched.endDate && formik.errors.endDate ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.endDate}</div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="col-6">
                <Form.Label className="col-12">Modifica ora fine</Form.Label>
                <Form.Control
                  type="time"
                  value={formik.values.allDay ? "" : formik.values.endTime}
                  name="endTime"
                  onChange={formik.handleChange}
                  className={getInputClasses("endTime")}
                  disabled={formik.values.allDay}
                />
                {formik.touched.endTime && formik.errors.endTime ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.endTime}</div>
                  </div>
                ) : null}
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleClose()} variant="danger">
            Annulla
          </Button>
          <Button onClick={formik.handleSubmit} variant="primary">
            Modifica
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const DeleteGestione = (id) => {
    const options = {
      headers: {
        Authorization:
          "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
      },
      method: "POST",
      body: JSON.stringify({
        idPresenza: id,
      }),
    };
    fetch(config.apiUrl + "calendar/DeleteGestione.php", options).then(() =>
      setCount(count + 1)
    );
    handleCloseDelete();
  };

  const ModalDelete = () => (
    <Modal show={showDelete} onHide={handleCloseDelete}>
      <Modal.Header>
        <Modal.Title>Elimina "{value.type}"</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Sei sicuro di voler eliminare definitivamente la voce alla data{" "}
        {value.date}?
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => DeleteGestione(value.id)} variant="danger">
          Elimina
        </Button>
        <Button onClick={() => handleCloseDelete()} variant="secondary">
          Annulla
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const ModalAdd = () => {
    const getInputClasses = (fieldname) => {
      if (formik.touched[fieldname] && formik.errors[fieldname]) {
        return "is-invalid";
      }

      if (formik.touched[fieldname] && !formik.errors[fieldname]) {
        return "is-valid";
      }

      return "";
    };

    const schema = Yup.object({
      type: Yup.string("Inserire voce").required("Inserire voce"),
      startDate: Yup.date("Inserire una data valida").required(
        "Inserire la data di inizio gestione"
      ),
      endDate: Yup.date("Inserire una data valida")
        .notRequired()
        .when(
          "startDate",
          (startDate, schema) => startDate && schema.min(startDate),
          "La data finale non può essere precedente a quella iniziale"
        ),
      startTime: Yup.string("Inserire l'ora di inizio").notRequired(),
      endTime: Yup.string("Inserire un orario di fine").notRequired(),
    });

    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }

    const formik = useFormik({
      initialValues: {
        type: "Presenza",
        allDay: false,
        startDate: formatDate(new Date()),
        startTime: "09:00",
        endDate: formatDate(new Date()),
        endTime: "18:00",
      },
      validationSchema: schema,
      enableReinitialize: true,
      onSubmit: (values) => {
        const options = {
          headers: {
            Authorization:
              "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
          },
          method: "POST",
          body: JSON.stringify({
            idUser: selectedUser,
            idOrganization: user.id_organization,
            idSupervisor: user.id,
            type: values.type,
            allDay: values.allDay,
            startDate: values.startDate,
            startTime: values.startTime,
            endDate: values.endDate,
            endTime: values.endTime,
          }),
        };
        fetch(config.apiUrl + "calendar/CreateGestione.php", options).then(() =>
          setCount(count + 1)
        );
        handleCloseAdd();
      },
    });

    return (
      <Modal show={showAdd} onHide={handleCloseAdd} size="lg">
        <Modal.Header>
          <Modal.Title>Crea nuova voce</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Row className="col-12">
              <Form.Group
                className={
                  formik.values.type !== "Presenza" ? "col-6" : "col-12"
                }
              >
                <Form.Label className="col-12">Voce</Form.Label>
                <select
                  className="form-control"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  name="type"
                >
                  <option value="Presenza">Presenza</option>
                  <option value="Assenza">Assenza</option>
                  <option value="Permesso">Permesso</option>
                  <option value="Ferie">Ferie</option>
                </select>
                {formik.touched.type && formik.errors.type ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.type}</div>
                  </div>
                ) : null}
              </Form.Group>
              {formik.values.type !== "Presenza" ? (
                <Form.Group className="col-6">
                  <Form.Label className="col-12">Intero giorno?</Form.Label>
                  <label className="checkbox mt-2">
                    <input
                      type="checkbox"
                      color="primary"
                      className="checkbox-inline"
                      checked={
                        formik.values.type !== "Presenza"
                          ? formik.values.allDay
                          : false
                      }
                      onChange={formik.handleChange}
                      name="allDay"
                    />
                    <span style={{ marginRight: "5px" }}></span>
                    {formik.values.allDay ? "Si" : "No"}
                  </label>
                </Form.Group>
              ) : null}
              <Form.Group className="col-6">
                <Form.Label className="col-12">Modifica data inizio</Form.Label>
                <Form.Control
                  type="date"
                  value={formik.values.startDate}
                  name="startDate"
                  onChange={formik.handleChange}
                  className={getInputClasses("startDate")}
                />
                {formik.touched.startDate && formik.errors.startDate ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.startDate}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="col-6">
                <Form.Label className="col-12">Modifica ora inizio</Form.Label>
                <Form.Control
                  type="time"
                  value={formik.values.allDay ? "" : formik.values.startTime}
                  name="startTime"
                  onChange={formik.handleChange}
                  disabled={formik.values.allDay}
                  className={getInputClasses("startTime")}
                />
                {formik.touched.startTime && formik.errors.startTime ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.startTime}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="col-6">
                <Form.Label className="col-12">Modifica data fine</Form.Label>
                <Form.Control
                  type="date"
                  value={formik.values.allDay ? "" : formik.values.endDate}
                  name="endDate"
                  onChange={formik.handleChange}
                  className={getInputClasses("endDate")}
                  disabled={formik.values.allDay}
                />
                {formik.touched.endDate && formik.errors.endDate ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.endDate}</div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="col-6">
                <Form.Label className="col-12">Modifica ora fine</Form.Label>
                <Form.Control
                  type="time"
                  value={formik.values.allDay ? "" : formik.values.endTime}
                  name="endTime"
                  onChange={formik.handleChange}
                  className={getInputClasses("endTime")}
                  disabled={formik.values.allDay}
                />
                {formik.touched.endTime && formik.errors.endTime ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.endTime}</div>
                  </div>
                ) : null}
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleCloseAdd()} variant="danger">
            Annulla
          </Button>
          <Button onClick={formik.handleSubmit} variant="primary">
            Crea
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const ButtonColumn = (props) => (
    <DataTypeProvider formatterComponent={editColum} {...props} />
  );

  const CurrencyTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={PresenzaFormatter} {...props} />
  );

  const columns = [
    { name: "id", title: "ID" },
    { name: "type", title: "Voce" },
    { name: "startDate", title: "Data inizio" },
    { name: "startTime", title: "Ora inizio" },
    { name: "endDate", title: "Data termine" },
    { name: "endTime", title: "Ora termine" },
    { name: "button", title: "Modifica" },
  ];
  const columnsUser = [
    { name: "id", title: "ID" },
    { name: "type", title: "Voce" },
    { name: "startDate", title: "Data inizio" },
    { name: "startTime", title: "Ora inizio" },
    { name: "endDate", title: "Data termine" },
    { name: "endTime", title: "Ora termine" },
  ];
  const rows = data;

  return (
    <>
      {parseInt(user.id_role) === roles.hr ||
      parseInt(user.id_role) === roles.admin ? (
        <>
          <ModalEdit />
          <ModalDelete />
          <ModalAdd />
        </>
      ) : null}
      <Card className="mt-3">
        <Card.Header>
          <div className="col-12 float-left">
            <h6 className="card-title-h3">TABELLA PRESENZE</h6>
          </div>

          <div className="col-4 float-right">
            {!loadingUsers || !loadingDepartments || !loadingTab ? (
              <CSVLink
                data={data}
                filename={"presenze.csv"}
                className="btn btn-primary btn-sm font-weight-bold"
                style={{
                  marginTop: "2%",
                  marginBottom: "1%",
                  float: "right",
                }}
                size={"small"}
                separator={";"}
              >
                <i className="fas fa-file-download"></i> Esporta CSV{" "}
              </CSVLink>
            ) : (
              <div className="float-right">
                <Skeleton
                  animation="wave"
                  variant="text"
                  width={130}
                  height={60}
                />
              </div>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          {parseInt(user.id_role) === roles.hr ||
          parseInt(user.id_role) === roles.admin ? (
            <Button
              onClick={() => handleOpenAdd()}
              className="float-right"
              size="sm"
            >
              Aggiungi voce
            </Button>
          ) : null}
          {parseInt(user.id_role) === roles.hr ||
          parseInt(user.id_role) === roles.admin ? (
            <Form>
              <Form.Row>
                {parseInt(user.id_role) === roles.admin ? (
                  <Form.Group className="col-6">
                    <Form.Label className="col-12">
                      Seleziona dipartimento
                    </Form.Label>
                    {!loadingDepartments ? (
                      <select
                        value={selectedDepartment}
                        onChange={(e) => {
                          setSelectedDepartment(e.target.value);
                          setSelectedUser(0);
                        }}
                        className="form-control col-6"
                      >
                        <option>Seleziona dipartimento</option>
                        <option value={user.idDepartment}>
                          {user.nameDepartment}
                        </option>
                        {departments.map((e, key) => {
                          return (
                            <option key={key} value={e.id}>
                              {e.nome}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <>
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={280}
                          height={60}
                        />
                      </>
                    )}
                  </Form.Group>
                ) : null}
                <Form.Group className="col-6">
                  <Form.Label className="col-12">
                    Seleziona dipendente
                  </Form.Label>
                  {!loadingUsers ? (
                    <select
                      value={selectedUser}
                      onChange={(e) => setSelectedUser(e.target.value)}
                      className="form-control col-6"
                    >
                      <option>Seleziona dipendente</option>
                      {selectedDepartment === user.idDepartment ? (
                        <option value={user.id}>Riepilogo personale</option>
                      ) : null}
                      {users.map((e, key) => {
                        return (
                          <option key={key} value={e.id}>
                            {e.fullname}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <>
                      <Skeleton
                        animation="wave"
                        variant="text"
                        width={280}
                        height={60}
                      />
                    </>
                  )}
                </Form.Group>
              </Form.Row>
            </Form>
          ) : null}
          {!loadingTab ? (
            <Grid
              rows={data}
              columns={
                parseInt(user.id_role) === roles.admin ||
                parseInt(user.id_role) === roles.hr
                  ? columns
                  : columnsUser
              }
            >
              <CurrencyTypeProvider for={presenzaCol} />
              {parseInt(user.id_role) === roles.admin ||
              parseInt(user.id_role) === roles.hr ? (
                <ButtonColumn for={buttonCol} />
              ) : null}
              <FilteringState defaultFilters={[]} />
              <SearchState value={searchValue} onValueChange={setSearchState} />
              <IntegratedFiltering />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
              <SortingState
                defaultSorting={[{ columnName: "id", direction: "desc" }]}
              />
              <IntegratedPaging />
              <IntegratedSorting />
              <Table tableComponent={TableComponent} />
              <TableColumnVisibility />
              <TableHeaderRow showSortingControls />
              <Toolbar />
              <SearchPanel />
              <TableColumnVisibility hiddenColumnNames={hiddenColumnNames} />
              <PagingPanel pageSizes={pageSizes} />
            </Grid>
          ) : (
            <>
              <Skeleton animation="wave" variant="text" height={30} />
              <Skeleton
                animation="wave"
                variant="circle"
                width={60}
                height={60}
              />
              <Skeleton animation="wave" variant="rect" height={230} />
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import config from "../../../../config/config";
import moment from "moment";
/*eslint-disable */
export function ModalFerie(props) {
  const { user } = useSelector((state) => state.auth);

  const getInputClassesForHandle = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const today = new Date();

  const schema = Yup.object().shape({
    startDate: Yup.date().required("Inserire una data di inizio ferie valida"),
    endDate: Yup.date()
      .required("Inserire una data di fine ferie valida")
      .min(
        Yup.ref("startDate"),
        "La data di fine ferie non può essere precedente a quella di inizio"
      ),
    descrizione: Yup.string()
      .notRequired()
      .max(100, "Massimo 100 caratteri per la descrizione")
      .min(8, "Minimo 8 caratteri per la descrizione"),
  });

  const formik = useFormik({
    initialValues: {
      startDate: moment(props.startDate).format("YYYY-MM-DD"),
      endDate: moment(props.endDate).format("YYYY-MM-DD"),
      descrizione: "",
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      const requestOptions = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
        body: JSON.stringify({
          idOrganization: user.id_organization,
          idUser: user.id,
          firstname: user.firstname,
          lastname: user.lastname,
          idDepartment: user.idDepartment,
          startDate: values.startDate,
          endDate: values.endDate,
          descrizione: values.descrizione,
        }),
      };

      fetch(config.apiUrl + "calendar/RichiestaFerie.php", requestOptions);
      props.handleCloseFerieShow();
    },
  });

  return (
    <Modal
      backdrop="static"
      show={props.ferieShow}
      onHide={props.handleCloseFerieShow}
    >
      <Modal.Header style={{ backgroundColor: "#caf0f8" }} closeButton>
        <Modal.Title>Richiedi ferie</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-12">
              {/* begin: data inizio ferie */}
              <label className="col-xl-12 col-lg-12 col-form-label">
                Data inizio
              </label>
              <div
                className="form-group fv-plugins-icon-container"
                style={{ maxWidth: "98%" }}
              >
                <input
                  required
                  placeholder="data inizio ferie"
                  type="date"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClassesForHandle(
                    "startDate"
                  )}`}
                  value={formik.values.startDate}
                  name="startDate"
                  {...formik.getFieldProps("startDate")}
                />
                {formik.touched.startDate && formik.errors.startDate ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.startDate}
                    </div>
                  </div>
                ) : null}
              </div>
              {/* end: data inizio ferie */}
            </div>

            <div className="col-12">
              {/* begin: Data fine ferie */}
              <label className="col-xl-12 col-lg-12 col-form-label">
                Data fine
              </label>
              <div
                className="form-group fv-plugins-icon-container"
                style={{ maxWidth: "98%" }}
              >
                <input
                  placeholder="Data fine ferie"
                  type="date"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClassesForHandle(
                    "endDate"
                  )}`}
                  value={formik.values.endDate}
                  min={moment(formik.values.startDate).format("YYYY-MM-DD")}
                  name="endDate"
                  {...formik.getFieldProps("endDate")}
                />
                {formik.touched.endDate && formik.errors.endDate ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.endDate}</div>
                  </div>
                ) : null}
              </div>
              {/* end: Data fine ferie */}
            </div>

            <div className="col-12">
              {/* begin: Descrizione */}
              <label className="col-xl-12 col-lg-12 col-form-label">
                Descrizione (falcoltativo)
              </label>
              <div
                className="form-group fv-plugins-icon-container"
                style={{ maxWidth: "98%" }}
              >
                <input
                  placeholder="Descrizione"
                  type="descrizione"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClassesForHandle(
                    "descrizione"
                  )}`}
                  value={formik.values.descrizione}
                  name="descrizione"
                  {...formik.getFieldProps("descrizione")}
                />
                {formik.touched.descrizione && formik.errors.descrizione ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.descrizione}
                    </div>
                  </div>
                ) : null}
              </div>
              {/* end: Descrizione */}
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleCloseFerieShow}>
          Annulla
        </Button>
        <Button onClick={formik.handleSubmit} variant="primary">
          Invia richiesta
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

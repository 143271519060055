import React from "react";
import { useSubheader } from "../../_metronic/layout";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export const Documenti = () => {
  /*eslint-disable */
  const suhbeader = useSubheader();
  suhbeader.setTitle("Documenti");

  return (
    <div className={`card card-custom`}>
      {/* Head */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">Documenti <small>(File Condivisi)</small></span>
        </h3>
        <div style={{float:"right"}} className="card-toolbar">
          <a href="#" className="btn btn-primary font-weight-bolder font-size-sm mr-3">Importa File</a>
          <a href="#" className="btn btn-primary font-weight-bolder font-size-sm mr-3">Condividi File</a>
          <a href="#" className="btn btn-primary font-weight-bolder font-size-sm mr-3">Scarica Lista</a>
        </div>
      </div>
      {/* Body */}
      <div className="card-body pt-0 pb-3">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-7" style={{ minWidth: "250px" }}><span className="text-dark-75">File</span></th>
                  <th className="pl-7" style={{ minWidth: "150px" }}><span className="text-dark-75">Condiviso da</span></th>
                  <th style={{ minWidth: "100px" }}>Data caricamento</th>
                  <th style={{ minWidth: "100px" }}>Dimensioni</th>
                  <th style={{ minWidth: "100px" }}>Tipo documento</th>
                  <th style={{ minWidth: "80px" }} />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50 symbol-light mr-4">
                        <span className="symbol-label">

                          <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/files/pdf.svg")} />
                          </span>
                        </span>
                      </div>
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Listino Prezzi</a>
                        <span className="text-muted font-weight-bold d-block">File condiviso</span>
                      </div>
                    </div>
                  </td>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Mario
                        Rossi</a>
                        <span className="text-muted font-weight-bold d-block">Team Director</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      08/03/2021
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      5MB
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      .PDF
                    </span>
                  </td>
                  <td className="pr-0 text-right">
                    <a href="#" className="btn btn-light-primary font-weight-bolder font-size-sm">Scarica</a>
                  </td>
                </tr>
                <tr>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50 symbol-light mr-4">
                        <span className="symbol-label">

                          <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/files/zip.svg")} />
                          </span>
                        </span>
                      </div>
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Documenti importanti</a>
                        <span className="text-muted font-weight-bold d-block">File condiviso</span>
                      </div>
                    </div>
                  </td>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      
                      <div>
                      <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Mario
                        Loffredo</a>
                        <span className="text-muted font-weight-bold d-block">Mobile Specialist</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      08/02/2021
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      100MB
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      .ZIP
                    </span>
                  </td>
                  <td className="pr-0 text-right">
                    <a href="#" className="btn btn-light-primary font-weight-bolder font-size-sm">Scarica</a>
                  </td>
                </tr>
                <tr>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50 symbol-light mr-4">
                        <span className="symbol-label">

                          <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/files/mp4.svg")} />
                          </span>
                        </span>
                      </div>
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Video Tutorial</a>
                        <span className="text-muted font-weight-bold d-block">File condiviso</span>
                      </div>
                    </div>
                  </td>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Antonia
                        Mazza</a>
                        <span className="text-muted font-weight-bold d-block">Mobile Director</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      08/01/2021
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      500MB
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      .MP4
                    </span>
                  </td>
                  <td className="pr-0 text-right">
                    <a href="#" className="btn btn-light-primary font-weight-bolder font-size-sm">Scarica</a>
                  </td>
                </tr>
                <tr>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50 symbol-light mr-4">
                        <span className="symbol-label">

                          <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/files/pdf.svg")} />
                          </span>
                        </span>
                      </div>
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Canvas Marzo</a>
                        <span className="text-muted font-weight-bold d-block">File condiviso</span>
                      </div>
                    </div>
                  </td>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Mario
                        Rossi</a>
                        <span className="text-muted font-weight-bold d-block">Team Director</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      01/03/2021
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      10MB
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      .PDF
                    </span>
                  </td>
                  <td className="pr-0 text-right">
                    <a href="#" className="btn btn-light-primary font-weight-bolder font-size-sm">Scarica</a>
                  </td>
                </tr>
                <tr>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-50 symbol-light mr-4">
                        <span className="symbol-label">

                          <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/files/pdf.svg")} />
                          </span>
                        </span>
                      </div>
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Guida</a>
                        <span className="text-muted font-weight-bold d-block">File condiviso</span>
                      </div>
                    </div>
                  </td>
                  <td className="pl-0 py-8">
                    <div className="d-flex align-items-center">
                      
                      <div>
                        <a href="#" className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">Mario
                        Rossi</a>
                        <span className="text-muted font-weight-bold d-block">Team Director</span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      20/12/2020
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      5MB
                    </span>
                  </td>
                  <td>
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      .PDF
                    </span>
                  </td>
                  <td className="pr-0 text-right">
                    <a href="#" className="btn btn-light-primary font-weight-bolder font-size-sm">Scarica</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import config from "../../../../config/config";
import moment from "moment";

export function ModalOvertime(props) {
  const { user } = useSelector((state) => state.auth);

  const getInputClassesForHandle = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const schema = Yup.object().shape({
    startDate: Yup.date().required("Inserire una data valida"),
    startTime: Yup.string().required("Inserire orario inizio straordinario"),
    endTime: Yup.string().required("Inserire ora fine straordinario"),
    descrizione: Yup.string()
      .notRequired()
      .max(100, "Massimo 100 caratteri per la descrizione")
      .min(8, "Minimo 8 caratteri per la descrizione"),
  });

  const formik = useFormik({
    initialValues: {
      startDate: moment(props.startDate).format("YYYY-MM-DD"),
      startTime: moment(new Date()).format("HH:mm"),
      endTime: moment(new Date()).format("HH:mm"),
      descrizione: "",
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      const requestOptions = {
        headers: {
          Authorization:
            "271c4d716fcf0e9555b51cffed666b4321f98f7f8bbeb9ae8bfc67752b4db8a2",
        },
        method: "POST",
        body: JSON.stringify({
          idUser: user.id,
          idDepartment: user.idDepartment,
          idOrganization: user.id_organization,
          firstname: user.firstname,
          lastname: user.lastname,
          startDate: values.startDate,
          startTime: values.startTime,
          endTime: values.endTime,
        }),
      };

      fetch(
        config.apiUrl + "calendar/RichiestaStraordinario.php",
        requestOptions
      );
      props.handleCloseStraordinarioShow();
    },
  });

  return (
    <Modal
      backdrop="static"
      show={props.straordinarioShow}
      onHide={() => props.handleCloseStraordinarioShow()}
    >
      <Modal.Header style={{ backgroundColor: "#caf0f8" }} closeButton>
        <Modal.Title>Richiedi straordinario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Form.Group className="col-12">
              <Form.Label className="col-12">Data straordinario</Form.Label>
              <Form.Control
                type="date"
                name="startDate"
                onChange={formik.handleChange}
                className={getInputClassesForHandle("startDate")}
                value={formik.values.startDate}
                {...formik.getFieldProps("startDate")}
              />
              {formik.touched.startDate && formik.errors.startDate ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.startDate}</div>
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className="col-6">
              <Form.Label className="col-12">Inizio straordinario</Form.Label>
              <Form.Control
                type="time"
                onChange={formik.handleChange}
                className={getInputClassesForHandle("startTime")}
                value={formik.values.startTime}
                name="startTime"
                {...formik.getFieldProps("startTime")}
              />
              {formik.touched.startTime && formik.errors.startTime ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.startTime}</div>
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className="col-6">
              <Form.Label className="col-12">Fine straordinario</Form.Label>
              <Form.Control
                type="time"
                onChange={formik.handleChange}
                className={getInputClassesForHandle("endTime")}
                value={formik.values.endTime}
                name="endTime"
                {...formik.getFieldProps("endTime")}
              />
              {formik.touched.endTime && formik.errors.endTime ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.endTime}</div>
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className="col-12">
              <Form.Label className="col-12">
                Descrizione (facoltativo)
              </Form.Label>
              <textarea
                style={{ resize: "none" }}
                rows="3"
                name="descrizione"
                onChange={formik.handleChange}
                placeholder="Descrizione... (facoltativo)"
                className={`form-control ${getInputClassesForHandle(
                  "descrizione"
                )}`}
                value={formik.values.descrizione}
                {...formik.getFieldProps("descrizione")}
              ></textarea>
              {formik.touched.descrizione && formik.errors.descrizione ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.descrizione}
                  </div>
                </div>
              ) : null}
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => props.handleCloseStraordinarioShow()}
        >
          Annulla
        </Button>
        <Button onClick={formik.handleSubmit} variant="primary">
          Invia richiesta
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
